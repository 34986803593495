import React from 'react';
import { graphql } from 'gatsby';

import Layout, { Content } from '../components/layout';
import { PageSubtitle, PageTitle } from '../components/typography';
import { SecondaryButton } from '../components/buttons';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Trans } from 'react-i18next';
import Seo from '../components/seo';

function NotFoundPage({ data, location }) {
    return (
        <Layout location={location}>
            <Seo title="404 - Nicht gefunden" />
            <Content className={'text-center py-24'}>
                <PageTitle>404</PageTitle>
                <PageSubtitle className="mb-10">
                    <Trans i18nKey="desc">
                        Die Seite konnte nicht gefunden werden.
                    </Trans>
                </PageSubtitle>
                <SecondaryButton to="/" className="inline-flex items-center">
                    <ChevronLeftIcon className="w-4 h-4" />
                    <Trans i18nKey="back">
                        <span>Zurück zur Homepage</span>
                    </Trans>
                </SecondaryButton>
            </Content>
        </Layout>
    );
}

export default NotFoundPage;

export const pageQuery = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["404"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
